import { keys } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import { manageEmailPreferencesActions } from '../actions/manage-email-preferences.actions';
import { RequestState } from '../constants/request-state';
import { StatisticsAlertOption } from '../types/amr-pipeline/enums/AlertOption';
import { AlertOption, EmailPreferences } from '../types/email-preferences/EmailPreferences';
import { IssuanceMonitorEmailPreferences } from '../types/email-preferences/IssuanceMonitorEmailPreferences';
import { AlertEnabledState } from '../types/enums/AlertEnabledState';
import { Currency } from '../types/enums/Currency';
import { Ratings } from '../types/enums/Rating';
import { ManageEmailPreferencesState } from '../types/state/ManageEmailPreferencesState';
import { DailyStatsAlertsConfig, CompanyAlertsConfig, FilterConfig, ImAlertConfig, SecondaryStatsAlertsConfig, WeeklyStatsAlertConfig } from '../types/user-config/UserConfig';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { mapConfig } from '../utils/amr-pipeline.utils';
import { emailPreferencesUtils } from '../utils/email-preferences.utils';
import { userConfigInitialState } from './im-user-config.reducer';
import { CloManagersEmailPreferences } from '../types/email-preferences/CloManagersEmailPreferences';
import { compareBy } from '../utils/compare.utils';
import { CloManager } from '../types/clo-managers/CloManager';
import { Bank } from '../types/banks/Bank';
import { BanksEmailPreferences } from '../types/email-preferences/BanksEmailPreferences';

const initialPreferences: EmailPreferences = {
    dailyDashboardEmail: AlertOption.Never,
    ratingPreference: [],
    hasPortfolio: false,
    portfolioBwicAlertState: AlertEnabledState.Disabled,
    portfolioDealersInventoryAlertState: AlertEnabledState.Disabled,
    newBwicAlertState: AlertEnabledState.Disabled,
    bwicReminderAlertState: AlertEnabledState.Disabled,
    bwicByRatingAlertState: AlertOption.Never,
    dealerInventoryAlertState: AlertOption.Never,
    productUpdates: false,
    eventsAndOffers: false,
    featuredContent: false
};

const initialState: ManageEmailPreferencesState = {
    initialPreferences: { ...initialPreferences },
    editPreferences: { ...initialPreferences },
    portfolioSavedFilterAlerts: {},
    editSavedFiltersPreferences: {},
    requestStateSaveEditPreferences: RequestState.none,
    initialIssuanceMonitorPreferences: undefined,
    editIssuanceMonitorPreferences: undefined,
    initialArrangerPipelinePreferences: undefined,
    editArrangerPipelinePreferences: undefined,
    initialCloManagersPreferences: undefined,
    editCloManagersPreferences: undefined,
    initialBanksPreferences: undefined,
    editBanksPreferences: undefined,
    requestStateGetPreferences: RequestState.none,
    requestStateSaveIssuanceMonitorPreferences: RequestState.none,
    requestStateSaveBwicByRating: RequestState.none
};

const setMasseditPreferences = (state: ManageEmailPreferencesState, checked: boolean) => {
    return state.editPreferences.ratingPreference.map(r => ({
        ...r,
        isUSD: checked,
        isEUR: checked
    }))
}

const getAlertsWithLegalName = (
    managers: { referenceName: string; legalName: string }[],
    managerAlerts?: CompanyAlertsConfig
) =>
    managers.map(m => {
        const managerConfig = managerAlerts?.value.find(a => a.companyReferenceName === m.referenceName);
        return {
            companyReferenceName: m.referenceName,
            legalName: m.legalName,
            alertOption: managerConfig?.alertOption || AlertOption.Never,
            referenceName: managerConfig?.referenceName,
        };
    });

const getSecondaryStatsAlertsWithLegalName = (managers: CloManager[], cloManagerAlerts?: SecondaryStatsAlertsConfig) =>
    managers.map(m => {
        const cloManagerConfig = cloManagerAlerts?.value.find(a => a.companyReferenceName === m.referenceName);
        const option = cloManagerConfig?.alertOption || AlertOption.Never;
        return {
            companyReferenceName: m.referenceName,
            legalName: m.legalName,
            alertOption: option as AlertOption.Instant | AlertOption.Never,
            referenceName: cloManagerConfig?.referenceName,
        };
    });

export const manageEmailPreferences = (
    state = initialState,
    action: ActionType<typeof manageEmailPreferencesActions[keyof typeof manageEmailPreferencesActions]>
): ManageEmailPreferencesState => {
    switch (action.type) {
        case getType(manageEmailPreferencesActions.getInitialDataRequest):
            return {
                ...state,
                requestStateGetPreferences: RequestState.request
            };
        case getType(manageEmailPreferencesActions.getInitialDataSuccess): {
            let issuanceMonitorAlerts = state.initialIssuanceMonitorPreferences;
            let arrangerPipelineAlerts = state.initialArrangerPipelinePreferences;
            let cloManagerAlerts = state.initialCloManagersPreferences;
            let bankAlerts = state.initialBanksPreferences;
            const config = mapConfig(action.payload.userConfig);
            const managers = action.payload.managers;
            const banks = action.payload.banks;
            const sortedManagers = managers?.sort(compareBy((m: CloManager) => m.legalName)) || [];
            const sortedBanks = banks?.sort(compareBy((b: Bank) => b.legalName)) || [];

            if (config.length) {
                const imAlertConfig = config.find(({ type }) => [UserConfigType.imAlert].includes(type)) as ImAlertConfig;
                const newTransactionAlertConfig = config.find(({ type }) => [UserConfigType.newPublishedTransactionAlert].includes(type)) as ImAlertConfig;
                const weeklyStatsAlertConfig = config.find(({ type }) => [UserConfigType.weeklyStatsEmail].includes(type)) as WeeklyStatsAlertConfig;
                const filtersConfig = config.find(({ type }) => [UserConfigType.imFilter].includes(type)) as FilterConfig;

                const arrangerPipelineAlert = config.find(({ type }) =>
                    [UserConfigType.arrangerPipelineAlert].includes(type),
                ) as ImAlertConfig;

                const cloManagerIssuanceMonitorAlert = config.find(({ type }) =>
                    [UserConfigType.cloManagerIssuanceMonitorAlert].includes(type),
                );

                const cloManagerAnalyticsAlert = config.find(({ type }) =>
                    [UserConfigType.cloManagerAnalyticsAlert].includes(type),
                ) as CompanyAlertsConfig;

                const cloManagerArrangerPipelineAlert = config.find(({ type }) =>
                    [UserConfigType.cloManagerArrangerPipelineAlert].includes(type),
                ) as CompanyAlertsConfig;

                const cloManagerBwicAlert = config.find(({ type }) =>
                    [UserConfigType.cloManagerBwicAlert].includes(type),
                ) as SecondaryStatsAlertsConfig;

                const cloManagerDealerInventoryAlert = config.find(({ type }) =>
                    [UserConfigType.cloManagerDealerInventoryAlert].includes(type),
                ) as SecondaryStatsAlertsConfig;

                const bankAnalyticsAlert = config.find(({ type }) =>
                    [UserConfigType.bankAnalyticsAlert].includes(type),
                ) as DailyStatsAlertsConfig;

                const bankDealerInventoryAlert = config.find(({ type }) =>
                    [UserConfigType.bankDealerInventoryAlert].includes(type),
                ) as SecondaryStatsAlertsConfig;

                const bankIssuanceMonitorAlert = config.find(({ type }) =>
                    [UserConfigType.bankIssuanceMonitorAlert].includes(type),
                ) as CompanyAlertsConfig;

                const bankArrangerPipelineAlert = config.find(({ type }) =>
                    [UserConfigType.bankArrangerPipelineAlert].includes(type),
                ) as CompanyAlertsConfig;

                issuanceMonitorAlerts = {
                    imAlertConfig: imAlertConfig || userConfigInitialState.imAlertConfig,
                    newTransactionAlertConfig: newTransactionAlertConfig || userConfigInitialState.newTransactionAlertConfig,
                    weeklyStatsAlertConfig: weeklyStatsAlertConfig || userConfigInitialState.weeklyStatsAlertConfig,
                    filtersConfig: {
                        [UserConfigType.imFilter]: filtersConfig || userConfigInitialState.filtersConfig.ImFilter
                    },
                };

                arrangerPipelineAlerts = {
                    apAlertConfig: arrangerPipelineAlert || userConfigInitialState.apAlertConfig
                }

                cloManagerAlerts = {
                    cloManagerIssuanceMonitorAlert: {
                        type: UserConfigType.cloManagerIssuanceMonitorAlert,
                        value: getAlertsWithLegalName(sortedManagers, cloManagerIssuanceMonitorAlert)
                    },
                    cloManagerArrangerPipelineAlert: {
                        type: UserConfigType.cloManagerArrangerPipelineAlert,
                        value: getAlertsWithLegalName(sortedManagers, cloManagerArrangerPipelineAlert)
                    },
                    cloManagerBwicAlert: {
                        type: UserConfigType.cloManagerBwicAlert,
                        value: getSecondaryStatsAlertsWithLegalName(sortedManagers, cloManagerBwicAlert)
                    },
                    cloManagerDealerInventoryAlert: {
                        type: UserConfigType.cloManagerDealerInventoryAlert,
                        value: getSecondaryStatsAlertsWithLegalName(sortedManagers, cloManagerDealerInventoryAlert)
                    },
                    cloManagerAnalyticsAlert: cloManagerAnalyticsAlert || userConfigInitialState.cloManagerAnalyticsAlert,
                }

                bankAlerts = {
                    bankAnalyticsAlert: bankAnalyticsAlert || userConfigInitialState.bankAnalyticsAlert,
                    bankDealerInventoryAlert: {
                        ...bankDealerInventoryAlert,
                        value: getAlertsWithLegalName(sortedBanks, bankDealerInventoryAlert),
                    } as SecondaryStatsAlertsConfig,
                    bankIssuanceMonitorAlert: {
                        ...bankIssuanceMonitorAlert,
                        value: getAlertsWithLegalName(sortedBanks, bankIssuanceMonitorAlert)
                    },
                    bankArrangerPipelineAlert: {
                        ...bankArrangerPipelineAlert,
                        value: getAlertsWithLegalName(sortedBanks, bankArrangerPipelineAlert)
                    },
                };
            }

            return {
                ...state,
                editPreferences: withPreferenceDefaults(action.payload.preferences),
                initialPreferences: withPreferenceDefaults(action.payload.preferences),
                editIssuanceMonitorPreferences: issuanceMonitorAlerts,
                initialIssuanceMonitorPreferences: issuanceMonitorAlerts,
                initialArrangerPipelinePreferences: arrangerPipelineAlerts,
                editArrangerPipelinePreferences: arrangerPipelineAlerts,
                editCloManagersPreferences: cloManagerAlerts,
                initialCloManagersPreferences: cloManagerAlerts,
                editBanksPreferences: bankAlerts,
                initialBanksPreferences: bankAlerts,
                requestStateGetPreferences: RequestState.success,
                mangers: sortedManagers,
                banks: sortedBanks,
            };
        }
        case getType(manageEmailPreferencesActions.setRatingCheckedState):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    ratingPreference: state.editPreferences.ratingPreference.map(r => r.rating === action.payload.rating
                        ? {
                            ...r,
                            isUSD: action.payload.currency === Currency.USD ? action.payload.checked : r.isUSD,
                            isEUR: action.payload.currency === Currency.EUR ? action.payload.checked : r.isEUR,
                        }
                        : r
                    )
                }
            };
        case getType(manageEmailPreferencesActions.setRatingInCurrencyCheckedState):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    ratingPreference: state.editPreferences.ratingPreference.map(r => ({
                        ...r,
                        isUSD: action.payload.currency === Currency.USD ? action.payload.checked : r.isUSD,
                        isEUR: action.payload.currency === Currency.EUR ? action.payload.checked : r.isEUR,
                    }))
                }
            };
        case getType(manageEmailPreferencesActions.setAllRatingCheckedState): {
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    ratingPreference: state.editPreferences.ratingPreference.map(r => ({
                        ...r,
                        isUSD: action.payload.checked,
                        isEUR: action.payload.checked
                    }))
                }
            };
        }
        case getType(manageEmailPreferencesActions.setRatingSize):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    ratingPreference: state.editPreferences.ratingPreference.map(r => r.rating === action.payload.rating
                        ? { ...r, [action.payload.type]: action.payload.size }
                        : r
                    )
                }
            };
        case getType(manageEmailPreferencesActions.setCheckedDailyDashboardEmail):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    dailyDashboardEmail: action.payload.alertOption
                }
            };
        case getType(manageEmailPreferencesActions.setPortfolioBwicAlerts):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    portfolioBwicAlertState: action.payload.status,
                }
            };
        case getType(manageEmailPreferencesActions.setDealerInventoryAlertState):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    dealerInventoryAlertState: action.payload.dealerInventoryAlertState,
                }
            };
        case getType(manageEmailPreferencesActions.setPortfolioInventoryAlerts):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    portfolioDealersInventoryAlertState: action.payload.status
                }
            };

        case getType(manageEmailPreferencesActions.setNewBiwicAlerts):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    newBwicAlertState: action.payload.status,
                }
            };
        case getType(manageEmailPreferencesActions.setBiwicReminderAlerts):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    bwicReminderAlertState: action.payload.status
                }
            };
        case getType(manageEmailPreferencesActions.unsubscribeAllBWICsNotifications):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    newBwicAlertState: AlertEnabledState.Disabled,
                    bwicReminderAlertState: AlertEnabledState.Disabled,
                }
            }
        case getType(manageEmailPreferencesActions.unsubscribeAllPortfolio):
            const portfolioSavedFilterAlerts = { ...state.portfolioSavedFilterAlerts };
            keys(portfolioSavedFilterAlerts).forEach(filterReferenceName =>
                portfolioSavedFilterAlerts[filterReferenceName] = {
                    bwicAlert: AlertOption.Never,
                    inventoryAlert: AlertOption.Never
                }
            );

            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    portfolioBwicAlertState: AlertEnabledState.Disabled,
                    portfolioDealersInventoryAlertState: AlertEnabledState.Disabled,
                },
                portfolioSavedFilterAlerts
            }
        case getType(manageEmailPreferencesActions.unsubscribeAllCloManagers):
            return {
                ...state,
                editCloManagersPreferences: {
                    cloManagerIssuanceMonitorAlert: state.editCloManagersPreferences?.cloManagerIssuanceMonitorAlert ? {
                        ...state.editCloManagersPreferences.cloManagerIssuanceMonitorAlert,
                        value: state.editCloManagersPreferences.cloManagerIssuanceMonitorAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.cloManagerIssuanceMonitorAlert,
                    cloManagerArrangerPipelineAlert: state.editCloManagersPreferences?.cloManagerArrangerPipelineAlert ? {
                        ...state.editCloManagersPreferences.cloManagerArrangerPipelineAlert,
                        value: state.editCloManagersPreferences.cloManagerArrangerPipelineAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.cloManagerIssuanceMonitorAlert,
                    cloManagerBwicAlert: state.editCloManagersPreferences?.cloManagerBwicAlert ? {
                        ...state.editCloManagersPreferences.cloManagerBwicAlert,
                        value: state.editCloManagersPreferences.cloManagerBwicAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.cloManagerBwicAlert,
                    cloManagerDealerInventoryAlert: state.editCloManagersPreferences?.cloManagerDealerInventoryAlert ? {
                        ...state.editCloManagersPreferences.cloManagerDealerInventoryAlert,
                        value: state.editCloManagersPreferences.cloManagerDealerInventoryAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.cloManagerDealerInventoryAlert,
                    cloManagerAnalyticsAlert: state.editCloManagersPreferences?.cloManagerAnalyticsAlert ? {
                        ...state.editCloManagersPreferences.cloManagerAnalyticsAlert,
                        value: state.editCloManagersPreferences.cloManagerAnalyticsAlert.value.map(a => a.companyReferenceName === action.payload.userCompanyReferenceName ? ({ ...a, alertOption: AlertOption.Never }) : a)
                    } : userConfigInitialState.cloManagerAnalyticsAlert,
                }
            }
        case getType(manageEmailPreferencesActions.unsubscribeAllBanks):
            return {
                ...state,
                editBanksPreferences: {
                    bankIssuanceMonitorAlert: state.editBanksPreferences?.bankIssuanceMonitorAlert ? {
                        ...state.editBanksPreferences.bankIssuanceMonitorAlert,
                        value: state.editBanksPreferences.bankIssuanceMonitorAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.bankIssuanceMonitorAlert,
                    bankArrangerPipelineAlert: state.editBanksPreferences?.bankArrangerPipelineAlert ? {
                        ...state.editBanksPreferences.bankArrangerPipelineAlert,
                        value: state.editBanksPreferences.bankArrangerPipelineAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.bankArrangerPipelineAlert,
                    bankDealerInventoryAlert: state.editBanksPreferences?.bankDealerInventoryAlert ? {
                        ...state.editBanksPreferences.bankDealerInventoryAlert,
                        value: state.editBanksPreferences.bankDealerInventoryAlert.value.map(a => ({ ...a, alertOption: AlertOption.Never }))
                    } : userConfigInitialState.bankDealerInventoryAlert,
                    bankAnalyticsAlert: state.editBanksPreferences?.bankAnalyticsAlert ? {
                        ...state.editBanksPreferences.bankAnalyticsAlert,
                        value: state.editBanksPreferences.bankAnalyticsAlert.value.map(a => a.companyReferenceName === action.payload.userCompanyReferenceName ? ({ ...a, alertOption: AlertOption.Never }) : a)
                    } : userConfigInitialState.bankAnalyticsAlert,
                }
            }
        case getType(manageEmailPreferencesActions.unsubscribeAllIssuanceMonitor):
            return {
                ...state,
                editIssuanceMonitorPreferences: state.editIssuanceMonitorPreferences ? {
                    imAlertConfig: {
                        ...state.editIssuanceMonitorPreferences.imAlertConfig,
                        value: {
                            alertOption: AlertOption.Never
                        },
                    },
                    newTransactionAlertConfig: {
                        ...state.editIssuanceMonitorPreferences.newTransactionAlertConfig,
                        value: {
                            alertOption: AlertOption.Never
                        },
                    },
                    weeklyStatsAlertConfig: {
                        type: UserConfigType.weeklyStatsEmail,
                        value: {
                            emailOption: StatisticsAlertOption.Never,
                        }
                    },
                    filtersConfig: {
                        [UserConfigType.imFilter]: {
                            type: UserConfigType.imFilter,
                            value: state.editIssuanceMonitorPreferences.filtersConfig.ImFilter.value.map(
                                imFilter => ({ ...imFilter, alertOption: AlertOption.Never })
                            )
                        }
                    }
                } : undefined
            }
        case getType(manageEmailPreferencesActions.rollbackDashboardEmails):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    dailyDashboardEmail: state.initialPreferences.dailyDashboardEmail
                }
            }
        case getType(manageEmailPreferencesActions.rollbackBWICPipelines):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    bwicByRatingAlertState: state.initialPreferences.bwicByRatingAlertState,
                    ratingPreference: [...state.initialPreferences.ratingPreference],
                }
            }
        case getType(manageEmailPreferencesActions.rollbackBWICNotifications):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    newBwicAlertState: state.initialPreferences.newBwicAlertState,
                    bwicReminderAlertState: state.initialPreferences.bwicReminderAlertState,
                }
            }
        case getType(manageEmailPreferencesActions.rollbackIssuanceMonitor):
            return {
                ...state,
                editIssuanceMonitorPreferences: state.initialIssuanceMonitorPreferences
            }
        case getType(manageEmailPreferencesActions.rollbackArrangerPipeline):
            return {
                ...state,
                editArrangerPipelinePreferences: state.initialArrangerPipelinePreferences
            }
        case getType(manageEmailPreferencesActions.rollbackCloManagers):
            return {
                ...state,
                editCloManagersPreferences: state.initialCloManagersPreferences
            }
        case getType(manageEmailPreferencesActions.rollbackBanks):
            return {
                ...state,
                editBanksPreferences: state.initialBanksPreferences
            }
        case getType(manageEmailPreferencesActions.rollbackPortfolio):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    portfolioBwicAlertState: state.initialPreferences.portfolioBwicAlertState,
                    portfolioDealersInventoryAlertState: state.initialPreferences.portfolioDealersInventoryAlertState,
                }
            }
        case getType(manageEmailPreferencesActions.rollbackInventory):
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    dealerInventoryAlertState: state.initialPreferences.dealerInventoryAlertState,
                }
            }
        case getType(manageEmailPreferencesActions.saveEmailPreferencesRequest):

            return {
                ...state,
                requestStateSaveEditPreferences: RequestState.request
            }
        case getType(manageEmailPreferencesActions.saveEmailPreferencesSuccess):
            return {
                ...state,
                requestStateSaveEditPreferences: RequestState.success,
                initialPreferences: withPreferenceDefaults(action.payload.emailPreferences),
                editPreferences: withPreferenceDefaults(action.payload.emailPreferences),
            }
        case getType(manageEmailPreferencesActions.saveEmailPreferencesFailure):
            return {
                ...state,
                requestStateSaveEditPreferences: RequestState.failure
            }
        case getType(manageEmailPreferencesActions.setBwicByRatingPreferences): {
            return {
                ...state,
                editPreferences: {
                    ...state.editPreferences,
                    bwicByRatingAlertState: action.payload.bwicByRatingAlertState,
                    ratingPreference: action.payload.bwicByRatingAlertState === AlertOption.Never
                        ? setMasseditPreferences(state, false)
                        : (state.editPreferences.bwicByRatingAlertState === AlertOption.Never
                            ? setMasseditPreferences(state, true)
                            : state.editPreferences.ratingPreference)
                }
            }
        }
        case getType(manageEmailPreferencesActions.setIssuanceMonitorSingleAlert): {
            return {
                ...state,
                editIssuanceMonitorPreferences: {
                    ...state.editIssuanceMonitorPreferences as IssuanceMonitorEmailPreferences,
                    imAlertConfig: {
                        type: UserConfigType.imAlert,
                        value: {
                            alertOption: action.payload.singleAlertState
                        },
                    },
                },
            }
        }
        case getType(manageEmailPreferencesActions.setArrangerPipelineAlert): {
            return {
                ...state,
                editArrangerPipelinePreferences: {
                    ...state.editArrangerPipelinePreferences,
                    apAlertConfig: {
                        type: UserConfigType.arrangerPipelineAlert,
                        value: {
                            alertOption: action.payload.apAlertOption
                        },
                    },
                },
            }
        }
        case getType(manageEmailPreferencesActions.setIssuanceMonitorNewTransactionAlert): {
            return {
                ...state,
                editIssuanceMonitorPreferences: {
                    ...state.editIssuanceMonitorPreferences as IssuanceMonitorEmailPreferences,
                    newTransactionAlertConfig: {
                        type: UserConfigType.newPublishedTransactionAlert,
                        value: {
                            alertOption: action.payload.transactionAlertState
                        },
                    },
                },
            }
        }
        case getType(manageEmailPreferencesActions.setIssuanceMonitorStatisticsAlert): {
            return {
                ...state,
                editIssuanceMonitorPreferences: {
                    ...state.editIssuanceMonitorPreferences as IssuanceMonitorEmailPreferences,
                    weeklyStatsAlertConfig: {
                        type: UserConfigType.weeklyStatsEmail,
                        value: {
                            emailOption: action.payload.statisticAlertState,
                        }
                    }
                },
            }
        }
        case getType(manageEmailPreferencesActions.setCloManagersAnalyticsAlert): {
            return {
                ...state,
                editCloManagersPreferences: {
                    ...state.editCloManagersPreferences as CloManagersEmailPreferences,
                    cloManagerAnalyticsAlert: action.payload.cloManagerAnalyticsAlert,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setCloManagersIssuanceMonitorAlert): {
            return {
                ...state,
                editCloManagersPreferences: {
                    ...state.editCloManagersPreferences as CloManagersEmailPreferences,
                    cloManagerIssuanceMonitorAlert: action.payload.cloManagerImAlertsConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setCloManagersArrangerPipelineAlert): {
            return {
                ...state,
                editCloManagersPreferences: {
                    ...state.editCloManagersPreferences as CloManagersEmailPreferences,
                    cloManagerArrangerPipelineAlert: action.payload.cloManagerImAlertsConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setCloManagersBwicAlert): {
            return {
                ...state,
                editCloManagersPreferences: {
                    ...state.editCloManagersPreferences as CloManagersEmailPreferences,
                    cloManagerBwicAlert: action.payload.cloManagerBwicAlert,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setCloManagersDealerInventoryAlert): {
            return {
                ...state,
                editCloManagersPreferences: {
                    ...state.editCloManagersPreferences as CloManagersEmailPreferences,
                    cloManagerDealerInventoryAlert: action.payload.cloManagerDealerInventoryAlert,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setBanksAnalyticsAlert): {
            return {
                ...state,
                editBanksPreferences: {
                    ...state.editBanksPreferences as BanksEmailPreferences,
                    bankAnalyticsAlert: action.payload.bankAnalyticsAlertConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setBanksDealerInventoryAlert): {
            return {
                ...state,
                editBanksPreferences: {
                    ...state.editBanksPreferences as BanksEmailPreferences,
                    bankDealerInventoryAlert: action.payload.bankDiAlertConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setBanksIssuanceMonitorAlert): {
            return {
                ...state,
                editBanksPreferences: {
                    ...state.editBanksPreferences as BanksEmailPreferences,
                    bankIssuanceMonitorAlert: action.payload.bankImAlertsConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setBanksArrangerPipelineAlert): {
            return {
                ...state,
                editBanksPreferences: {
                    ...state.editBanksPreferences as BanksEmailPreferences,
                    bankArrangerPipelineAlert: action.payload.bankApAlertsConfig,
                }
            }
        }
        case getType(manageEmailPreferencesActions.setIssuanceMonitorFiltersAlert): {
            return {
                ...state,
                editIssuanceMonitorPreferences: {
                    ...state.editIssuanceMonitorPreferences as IssuanceMonitorEmailPreferences,
                    filtersConfig: {
                        [UserConfigType.imFilter]: {
                            type: UserConfigType.imFilter,
                            value: action.payload.filtersAlertState,
                        },
                    },
                }
            }
        }
        case getType(manageEmailPreferencesActions.saveRequest):
        case getType(manageEmailPreferencesActions.saveBanksRequest):
        case getType(manageEmailPreferencesActions.saveArrangerPipelineRequest):
            return { ...state, requestStateSaveIssuanceMonitorPreferences: RequestState.request };
        case getType(manageEmailPreferencesActions.saveSuccess):
            return { ...state, requestStateSaveIssuanceMonitorPreferences: RequestState.success };
        case getType(manageEmailPreferencesActions.saveFailure):
            return { ...state, requestStateSaveIssuanceMonitorPreferences: RequestState.failure };
        case getType(manageEmailPreferencesActions.saveBwicByRatingPreferencesRequest):
            return { ...state, requestStateSaveBwicByRating: RequestState.request };
        case getType(manageEmailPreferencesActions.saveBwicByRatingPreferencesSuccess):
            return { ...state, requestStateSaveBwicByRating: RequestState.success };
        case getType(manageEmailPreferencesActions.saveBwicByRatingPreferencesFailure):
            return { ...state, requestStateSaveBwicByRating: RequestState.failure };
        case getType(manageEmailPreferencesActions.savedFilterAlertOptionChange):
            return {
                ...state,
                editSavedFiltersPreferences: {
                    ...state.editSavedFiltersPreferences,
                    ...action.payload.updated
                }
            };
        case getType(manageEmailPreferencesActions.savedFilterAlertOptionsReset):
            return { ...state, editSavedFiltersPreferences: {} };
        case getType(manageEmailPreferencesActions.reset):
            return { ...initialState };
        case getType(manageEmailPreferencesActions.initPortfolioFilterAlerts):
            return { ...state, portfolioSavedFilterAlerts: action.payload.portfolioFilterAlerts }
        case getType(manageEmailPreferencesActions.portfolioSavedFilterAlertChange):
            return {
                ...state,
                portfolioSavedFilterAlerts: {
                    ...state.portfolioSavedFilterAlerts,
                    [action.payload.filterReferenceName]: {
                        bwicAlert: action.payload.bwicAlert,
                        inventoryAlert: action.payload.inventoryAlert
                    }
                }
            }
        case getType(manageEmailPreferencesActions.portfolioSavedFilterGlobalAlertChange): {
            const portfolioSavedFilterAlerts = { ...state.portfolioSavedFilterAlerts };

            keys(portfolioSavedFilterAlerts).forEach(filterReferenceName =>
                portfolioSavedFilterAlerts[filterReferenceName] = {
                    bwicAlert: action.payload.bwicAlert ?? portfolioSavedFilterAlerts[filterReferenceName].bwicAlert,
                    inventoryAlert: action.payload.inventoryAlert ?? portfolioSavedFilterAlerts[filterReferenceName].inventoryAlert
                }
            );

            return { ...state, portfolioSavedFilterAlerts }
        }
        default:
            return state;
    }
};

const withPreferenceDefaults = (preferences: EmailPreferences): EmailPreferences => {
    return {
        ...preferences,
        ratingPreference: Ratings.map(r => preferences.ratingPreference.find(rp => rp.rating === r)
            ?? emailPreferencesUtils.getDefaultRatingPreferences(r)
        )
    };
};
